import CarDetailsComponent from "../Components/Create Job Posting/CarDetailsComponent";
import SelectJobComponent from "../Components/Create Job Posting/selectJob";
import { useGlobalState } from "../global";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CreateJob = () => {

    const job = useGlobalState('customerJob')

    const navigate = useNavigate();

    const registration = useGlobalState('carRegistration')

    const [hasError, setHasError] = useState(false);


    const handleClick = () => {

        if (job[0] === null) {
            setHasError(true)
        } else {
            navigate('/create-job/details')
        }
    }

    return (

        <div className="w-full pt-10 mb-10">
            <div className="flex flex-col items-center">
                <div className="text-center items-center mb-4">
                    <p className="text-[#126AF6] text-2xl font-bold mb-4">Fixing your car just got easier</p>
                    <p className="text-[#1C3A54]"><span className="font-bold">Sit back , Relax and wait for your quotes</span></p>
                </div>

                <CarDetailsComponent reg={registration} />
                <SelectJobComponent />

                {hasError ? <p className='text-gray-700 font-medium w-3/4 mt-2'>Please select a job before trying to continue.</p> : <></>}


                <div className="flex px-10 w-full justify-center">
                    <button onClick={() => handleClick()} className="bg-gray-700 w-full mt-6 py-3 md:w-full lg:w-1/2 text-white">Confirm</button>
                </div>
            </div>
        </div>
    )
}

export default CreateJob 