import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { setGlobalState, useGlobalState } from "../../global"
import mot from "../../Assets/Icons/Wiper.png"
import brake from "../../Assets/Icons/clutch.png"
import clutch from "../../Assets/Icons/gearbox.png"
import mobile from "../../Assets/Icons/tools.png"

const SelectJobComponent = (props) => {

    const job = useGlobalState('customerJob')
    const image = useGlobalState('jobImage')

    console.log(job)

    const handleClick = (image , title) => {
       console.log(title)
        setGlobalState('jobImage',image)
        setGlobalState('customerJob',title)
    }

    if(job[0] === null){
        console.log(true)
    }
    else{
        console.log(false)
    }
    

    return (
        <>
            {job[0] === null ? <div className="mt-8 w-full px-4 lg:w-1/2">
                <form>
                    <fieldset className="border border-1 rounded-lg px-8 py-4">
                        <legend className="w-auto px-2">What are you looking for? *</legend>
                        <p className="mb-4 font-semibold text-mg text-[#4A4F68]">Quick Select</p>
                        <div className="mb-4 flex flex-row  justify-between">
                            <div className="items-center cursor-pointer" onClick={() => handleClick(mot,'Servicing and MOT')}>
                                <div className="items-center justify-center text-center">
                                <img src={ mot} width={50} height={50} />
                                <p className="text-sm mb-2 mt-2 text-[#4A4F68] font-medium">MOT</p>
                                </div>
                            </div>
                            <div className="items-center cursor-pointer" onClick={() => handleClick(clutch,'Clutch Repairs')}>
                                <div className="items-center justify-center text-center">
                                <img src={ clutch} width={50} height={50} />
                                <p className="text-sm mb-2 mt-2 text-[#4A4F68] font-medium">Clutch</p>
                                </div>
                            </div>
                            <div className="items-center cursor-pointer" onClick={() => handleClick(brake,'Brake Repairs')}>
                                <div className="items-center justify-center text-center">
                                <img src={ brake} width={50} height={50} />
                                <p className="text-sm mb-2 mt-2 text-[#4A4F68] font-medium">Brake</p>
                                </div>
                            </div>
                            <div className="items-center cursor-pointer" onClick={() => handleClick(mobile,'Mobile Mechanics and Services')}>
                                <div className="items-center justify-center text-center">
                                <img src={ mobile} width={50} height={50} />
                                <p className="text-sm mb-2 mt-2 text-[#4A4F68] font-medium">Mobile</p>
                                </div>
                            </div>

                        </div>
                        <Link to="/select-job" className="text-gray-500 text-sm">View All Categories</Link>
                    </fieldset>
                </form>
            </div>: <div className="mt-8 w-full px-4 lg:w-1/2">
                <form>
                    <fieldset className="border border-1 rounded-lg px-8 py-4 ">
                        <legend className="w-auto px-2">What are you looking for? *</legend>
                        <div className="mb-4">
                            <div className="flex flex-row items-center">
                                <img src={image ? image[0] : '../Assets/Icons/wheel.png'} width={50} height={50} />
                                <p className="text-xl mb-2 ml-4">{job ? job : 'Select a job'}</p>
                            </div>

                        </div>
                        <Link to="/select-job" className="text-gray-500 text-sm">View All Categories</Link>
                    </fieldset>
                </form>
            </div>}


        </>
    )
}

export default SelectJobComponent